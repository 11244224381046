import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
const HomeCommunity = (props) => {
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={props.sectionImage.childImageSharp.fluid}
        className="banner-community u-relative"
      >
        <div className="u-center-text review-container">
          <h2 className="type-header__locations type-emph type-black">
            Fitness <span className="type-heavy">COMMUNITY</span>
          </h2>
        </div>
        <div className="review-position">
          <div className="review-shape">
            <figure className="review-circle ">
              <figcaption className="type-heavy type-green review-caption">
                CF
              </figcaption>
            </figure>
            <div className="review-text">
              <h3 className="review-header type-heavy type-black">
                CrossFit - Anna A
              </h3>
              <p className="type-reg type-body review-text__body">
                At 59, I was a long term exerciser and gym member, bored and
                frustrated by the lack of results. After one year of CrossFit, I
                have lost weight, lowered my cholesterol, LDL's and improved my
                bone density. FitFarm has changed my life!
              </p>
            </div>
          </div>
          <div className="review-shape u-mt-2">
            <figure className="review-circle ">
              <figcaption className="type-heavy type-green review-caption">
                CF
              </figcaption>
            </figure>
            <div className="review-text">
              <h3 className="review-header type-heavy type-black">
                CrossFit Classes - Mike Snow
              </h3>
              <p className="type-reg type-body">
                When I started I was overweight, had bad shoulders, and very
                little experience. I had tried other CrossFit boxes before, but
                none of them felt like home. My wife and four kids have all
                joined classes. It has become an important part of our lives and
                has brought us closer as a family.
              </p>
            </div>
          </div>
          <div className="u-center-text u-mt-5">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__black type-heavy type-black"
            >
              Join our Community
            </Link>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HomeCommunity

import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"

const WhatIsCrossfit = (props) => {
  return (
    <>
      <section className="container container-black">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A group of members in a class with barbells lifted over their heads."
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-white type-header__primary type-header__primary-l">
          <span className="type-emph type-break__after">What is</span> CrossFit?
        </h2>
        <div className="container-type__white">
          <p className="type-reg type-white type-body">
            CrossFit is a lifestyle, a sport, and a community. With good
            coaching and proper training, CrossFit can be used to achieve any
            goal, health, fitness, and weight loss.
          </p>
          <h3 className="type type-heavy type-yellow type-h3">
            Variety of Workouts
          </h3>
          <p className="type-reg type-white type-body u-mt-1">
            In CrossFit, you will perform cardio, weightlifting, and gymnastic
            exercises. The workout of the day, or WOD, continuously challenges
            your muscles, because of the variety of exercises you will perform.
          </p>
          <h3 className="type type-heavy type-yellow type-h3">
            Functional Movements
          </h3>
          <p className="type-reg type-white type-body u-mt-1">
            CrossFit enhances your life. This is because the workouts include
            exercises that mimic movements you do in everyday life.
          </p>
          <h3 className="type type-heavy type-yellow type-h3">
            High intensity
          </h3>
          <p className="type-reg type-white type-body u-mt-1">
            Our classes use interval training mixing bouts of work and rest in
            timed intervals. These high-intensity workouts help you gain
            strength, stamina and improve your health.
          </p>
          <h3 className="type type-heavy type-yellow type-h3">
            Is CrossFit Safe?
          </h3>
          <p className="type-reg type-white type-body u-mt-1">
            Yes. We adjust every workout for every kind of person. This is true
            whether a person is young or old, slim or overweight, healthy or
            injured.
          </p>
          <div className="u-mt-2">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-1"
            >
              Join a Class
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default WhatIsCrossfit

import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import BackLogo from "assets/images/fitfarm-logo-for-white-banner.svg"
import Logo from "assets/images/fitfarm-green-color-logo-only.svg"

const CrossFitBanner = (props) => {
  return (
    <>
      <section className="banner banner-white">
        <div className="banner-crossfit">
          <Img
            fluid={props.leftImage.childImageSharp.fluid}
            alt="A blonde woman with an intense expression lifting a kettle bell."
            className="banner-crossfit__img"
          />
        </div>
        <div className="banner-service__header">
          <img
            src={Logo}
            className="banner-service__logo"
            alt="fitfarm green colored logo"
          />
          <h1 className="type  type-heavy type-header__service type-break__after">
            Join Our Classes{" "}
            <span className="type  type-reg type-header__service-skew">
              Omaha CrossFit Gym
            </span>
          </h1>
          <p className="type-reg  type-body type-header__service-caption u-center-text u-mt-2">
            Improve your health and fitness, with our CrossFit Classes
          </p>
          <Link
            to="/contact-us/"
            className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-2"
          >
            Get Started
          </Link>
        </div>
        <div className="banner-overlay"></div>
        <img
          src={BackLogo}
          className="img-background img-background__right"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default CrossFitBanner
